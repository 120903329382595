import React, { Component } from 'react'
import moment from 'moment'

import DisclaimerModal from '../terms/DisclaimerModal'
import TermsModal from '../terms/TermsModal'

export default class Footer extends Component {
    constructor (props) {
        super(props)
        this.state = {
            shouldShowDisclaimerModal: false,
            shouldShowTermsModal: false
        }
    }
    render () {
        const { shouldShowDisclaimerModal, shouldShowTermsModal } = this.state
        return (
            <footer className='footer'>
                <div className='footer--main'>
                    <div className='footer--links'>
                        <button className='footer--disclaimer-button' onClick={() => { this.setState({ shouldShowDisclaimerModal: true }) }}>{`Website Disclaimer`}</button>
                        <button className='footer--disclaimer-button' onClick={() => { this.setState({ shouldShowTermsModal: true }) }}>{`Terms of Use`}</button>
                    </div>
                    <div className='footer--copyright'>
                        {`© Antelope Technology ${moment().format('YYYY')}. `}
                        <br />
                        {`All Rights Reserved`}
                        </div>
                </div>
                {shouldShowDisclaimerModal && <DisclaimerModal onClickClose={() => { this.setState({ shouldShowDisclaimerModal: false }) }} />}
                {shouldShowTermsModal && <TermsModal onClickClose={() => { this.setState({ shouldShowTermsModal: false }) }} />}
            </footer>
        )
    }
}