import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { RxCross1 } from 'react-icons/rx'

Modal.setAppElement('#root')

export default class TermsModal extends Component {
    constructor (props) {
        super(props)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleCloseModal () {
        const { onClickClose } = this.props
        onClickClose()
    }

    render () {
        return (
            <Modal overlayClassName='terms-modal--overlay'
                className='terms-modal'
                isOpen
                shouldCloseOnOverlayClick
                onRequestClose={this.handleCloseModal}>
                <div className='terms-modal--main horizontal-centered'>
                    <div className='terms-modal--header'>
                        <div className='terms-modal--title'>{`Terms of Use`}</div>
                        <button className='terms-modal--close-button' 
                            onClick={this.handleCloseModal}><RxCross1 /></button>
                    </div>
                    <div className='terms-modal--body'>
                        <p>
                            {`The terms and conditions of use set forth below (the "`}
                            <strong>{'Terms'}</strong>
                            {`") apply to all webpages on this website (collectively, the "`}
                            <strong>{'Website'}</strong>
                            {`").`}
                        </p>
                        <p>
                            {`Please read these Terms carefully before visiting the Website. 
                            By using the Website, you acknowledge that you have read, understood and agree to be bound by these Terms. 
                            If you do not agree to any of the Terms, you are not authorized to use this Website. 
                            You may reject these terms by ceasing to use this Website and leaving this Website at any time. 
                            Please also note that these Terms are in addition to any other agreements between you and Antelope Technology Limited ("`}
                            <strong>{'Antelope Technology'}</strong>
                            {`", "`}
                            <strong>{'we'}</strong>
                            {`", "`}
                            <strong>{'us'}</strong>
                            {`" or "`}
                            <strong>{'our'}</strong>
                            {`" as the case may be).`}
                        </p>
                        <p>
                            {`Antelope Technology reserves the right to change, modify, add to or remove provisions in these Terms at any time for any reason and in our sole discretion without notice. 
                            Your use of the Website is governed by the version of the Terms in effect on the date this Website is accessed by you. 
                            If you use the Website after the Terms have been amended, you will be deemed to have agreed to the Terms as amended. `}
                        </p>
                        <p>
                            {`The information and materials on the Website may contain typographical errors or inaccuracies. 
                            Any dated information is valid as of its date only, and Antelope Technology is not responsible for updating or amending any such information. `}
                        </p>
                        <p>
                            {`Antelope Technology may discontinue or modify any information provided on the Website at any time without prior notice. 
                            You agree that Antelope Technology and its subsidiaries and affiliates will not be liable to you or to any third party for any such modification, suspension or discontinuance.`}
                        </p>
                        <h3>{`Prohibited Uses`}</h3>
                        <p>
                            {`You shall not conduct, facilitate, authorise or permit any text or data mining or web scraping in relation to our Website or any information provided via, or in relation to, our Website for any purpose, including the development, training, fine-tuning or validation of AI systems or models. 
                            This includes using (or permitting, authorising or attempting the use of): `}
                        </p>
                        <p>{`a) any "robot", "bot", "spider", "scraper" or other automated device, program, tool, algorithm, code, process or methodology to access, obtain, copy, monitor or republish any portion of our Website or any data, content or information accessed via the same; and `}</p>
                        <p>{`b) Any automated analytical technique aimed at analysing text and data in digital form to generate information or develop, train, fine-tune or validate AI systems or models which includes but is not limited to patterns, trends and correlations. `}
                        </p>
                        <h3>{`No Offer/Local Restrictions`}</h3>
                        <p>
                            {`Nothing contained in or on the Website constitutes investment, legal or tax advice. 
                            Nothing contained in or on the Website constitutes a solicitation, recommendation or offer by Antelope Technology or any entity managed directly or indirectly by Antelope Technology, to buy, acquire, sell or dispose of any security, commodity, or investment, or to engage in any other transaction. 
                            The user agrees to bear sole responsibility for the consequences of any decisions made by the user based on and/or in reliance on information available on the Website.`}
                        </p>
                        <p>
                            {`The information provided on the Website is not intended for distribution to, or use by, any person or entity in any jurisdiction or country where such distribution or use would be contrary to law or regulation. 
                            All persons and entities accessing the Website do so of their own initiative and are responsible for compliance with applicable local laws and regulations. 
                            The Website is not directed to any person in any jurisdiction where the publication or availability of the Website is prohibited, by reason of that person's nationality, residence or otherwise. 
                            Persons subject to these restrictions must not access the Website.`}
                        </p>
                        <p>
                            {`In no event shall Antelope Technology be responsible or liable for loss or damage of any kind whatsoever arising directly or indirectly as a result of the content and information on this site.`}
                        </p>
                        <h3>{`Authorized User`}</h3>
                        <p>
                            {`Certain pages of the Website, are available only to users who have been authorized by us to access those web pages. 
                            Such authorization may require completion of an accredited investor questionnaire and satisfactory background information screening.`}
                        </p>
                        <p>
                            {`Unauthorized use of the Website and/or our systems, including, but not limited to, unauthorized entry into and/or any attempted access of Antelope Technology's systems and/or any restricted areas of any of the Website, misuse or sharing of passwords or misuse of any other information, is strictly prohibited.`}
                        </p>
                        <h3>{`Copyright and Trademarks`}</h3>
                        <p>
                            {`All copyright, trademarks, service marks, trade names, and logos displayed on this Website (collectively referred to as the "Marks") are proprietary to Antelope Technology or their respective owners.
                            Any use of the Marks, or any other Marks owned by or licensed to Antelope Technology without Antelope Technology's express written consent, is strictly prohibited.`}
                        </p>
                        <h3>{`No Warranty, Limitation of Liability`}</h3>
                        <p>
                            {`THE INFORMATION ON THE WEBSITE IS PROVIDED ON AN "AS IS" BASIS FOR GENERAL INFORMATION ONLY. 
                            THE INFORMATION ON THE WEBSITE IS NOT INTENDED TO AMOUNT TO ADVICE ON WHICH YOU SHOULD RELY. 
                            ANTELOPE TECHNOLOGY DOES NOT MAKE ANY REPRESENTATIONS, WARRANTIES OR GUARANTEES, WHETHER EXPRESS OR IMPLIED, AS TO THE ACCURACY OF THE INFORMATION AND/OR MATERIALS PROVIDED ON THIS WEBSITE FOR ANY PARTICULAR PURPOSE AND EXPRESSLY DISCLAIMS ANY WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE.`}
                        </p>
                        <p>
                            {`TO THE MAXIMUM EXTENT PERMITTED BY LAW, 
                            ANTELOPE TECHNOLOGY SHALL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL, SPECIAL, PUNITIVE, EXEMPLARY OR CONSEQUENTIAL DAMAGES, 
                            INCLUDING BUT NOT LIMITED TO DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM YOUR USE OF OR INABILITY TO USE THE WEBSITE, 
                            WHETHER BASED ON WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT ANTELOPE TECHNOLOGY HAS BEEN INFORMED OF OR COULD FORESEE THE POSSIBILITY OF SUCH DAMAGE. `}
                        </p>
                        <p>
                            {`TO THE MAXIMUM EXTENT PERMITTED BY LAW, IN NO EVENT SHALL ANTELOPE TECHNOLOGY'S TOTAL LIABILITY ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM YOUR USE OR INABILITY TO USE THE WEBSITE EXCEED ONE HUNDRED US DOLLARS. 
                            YOU FURTHER ACKNOWLEDGE THAT NOTHING CONTAINED ON THIS WEBSITE CONSTITUTES INVESTMENT, LEGAL, TAX OR OTHER ADVICE, NOR IS IT TO BE RELIED UPON IN MAKING ANY INVESTMENT OR OTHER DECISIONS. 
                            YOU SHOULD SEEK PROFESSIONAL ADVICE PRIOR TO MAKING ANY INVESTMENT DECISIONS.`}
                        </p>
                        <h3>{`Severability`}</h3>
                        <p>
                            {`If any of the provisions of these Terms are deemed unlawful or for any reason unenforceable, the same shall be inoperative only to the extent necessary to achieve compliance with applicable law and shall not affect the remaining Terms, which shall be given full effect, without regard to the invalid portion(s).`}
                        </p>
                        <h3>{`Governing Law and Dispute Resolution`}</h3>
                        <p>
                            {`These terms and any action related thereto shall be governed by the laws of Singapore without regard to conflict of law principles of Singapore.`}
                        </p>
                        <p>
                            {`Any dispute arising out of or in connection with these Terms, including any question regarding their existence, validity or termination, shall be referred to and finally resolved by arbitration administered by the Singapore International Arbitration Centre ("`}
                            <strong>{'SIAC'}</strong>
                            {`") in accordance with the Arbitration Rules of the Singapore International Arbitration Centre ("`}
                            <strong>{'SIAC Rules'}</strong>
                            {`") for the time being in force, which rules are deemed to be incorporated by reference in this clause. 
                            The seat of arbitration shall be Singapore. 
                            The tribunal shall consist of one arbitrator. 
                            The language of the arbitration shall be English. `}
                        </p>
                    </div>
                </div>
            </Modal>
        )
    }
}

TermsModal.propTypes = {
    onClickClose: PropTypes.func.isRequired
}

TermsModal.defaultProps = {
    onClickClose: () => {}
}