import React, { Component, Fragment } from 'react'
import PropTypes from 'prop-types'
import _ from 'lodash'

import { RxCross1, RxListBullet } from 'react-icons/rx'
import { FaChartLine, FaHandshake } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'

import Footer from './Footer'
import { IoMdCog } from 'react-icons/io'

export const LANDING_LINK_KEY = 'LANDING_LINK_KEY'

const LinkStruct = ({ key='', name='', path='/', icon=null, disabled=false, subLinkItems=[], isExternal=false }) => {
    return {
        key,
        name,
        path,
        icon,
        disabled,
        subLinkItems,
        isExternal
    }
}

export const LINK = {
    PROP_TRADING: LinkStruct({
        key: 'PROP_TRADING',
        name: 'Prop Trading',
        path: null,
        icon: <FaChartLine />
    }),
    OTC: LinkStruct({
        key: 'OTC',
        name: 'OTC',
        path: null,
        icon: <FaHandshake />
    }),
    CAREER: LinkStruct({
        key: 'CAREER',
        name: 'Career',
        path: null,
        icon: <IoMdCog />
    }),
    CONTACT: LinkStruct({
        key: 'CONTACT',
        name: 'Contact Us',
        path: null,
        icon: <MdEmail />
    })
}

export default class Header extends Component {

    constructor (props) {
        super(props)
        this.state = {
            shouldShowMobileMenuList: false
        }
    }

    MobileMenu () {
        const { onClickLink } = this.props
        const { shouldShowMobileMenuList } = this.state
        return (
            <div className={'header--mobile-menu' + (shouldShowMobileMenuList ? ' show-list' : '')}>
                <div className='header--mobile-menu--header'>
                    <div className='header--mobile-menu--logo'>
                        <label onClick={() => { 
                            if (shouldShowMobileMenuList) {
                                this.setState({ shouldShowMobileMenuList: false })
                            }
                            onClickLink(LANDING_LINK_KEY) 
                        }}>{`Antelope Technology`}</label>
                    </div>
                    <button className='header--mobile-menu--trigger-button'
                        onClick={() => {
                            this.setState({ shouldShowMobileMenuList: !shouldShowMobileMenuList })
                        }}>{shouldShowMobileMenuList ? <RxCross1 /> : <RxListBullet />}</button>
                </div>
                {shouldShowMobileMenuList &&
                <div className='header--mobile-menu--list'>
                    <div className='header--mobile-menu--list--main'>
                        {_.map(LINK, link => {
                            const { key, name, icon } = link
                            return (
                                <button key={key} 
                                    className='header--mobile-menu--list--tab'
                                    onClick={() => { 
                                        this.setState({ shouldShowMobileMenuList: false })
                                        onClickLink(key) 
                                    }}>
                                    {icon}
                                    {name}
                                </button>
                            )
                        })}
                    </div>
                    <div className='header--mobile-menu--list--footer'>
                        <Footer />
                    </div>
                </div>}
            </div>
        )
    }

    render () {
        const { onClickLink } = this.props
        return (
            <Fragment>
                <div className='header'>
                    <div className='header--menus left'>
                        {_.map(_.pick(LINK, [LINK.PROP_TRADING.key, LINK.OTC.key]), link => {
                            const { key, name } = link
                            return (
                                <button key={key} 
                                    className='header--menus--tab'
                                    onClick={() => { onClickLink(key) }}>
                                    {name}
                                </button>
                            )
                        })}
                    </div>
                    <div className='header--logo'>
                        <div className='header--logo--main' onClick={() => { onClickLink(LANDING_LINK_KEY) }}>
                            {'Antelope Technology'}
                        </div>
                    </div>
                    <div className='header--menus right'>
                        {_.map(_.pick(LINK, [LINK.CAREER.key, LINK.CONTACT.key]), link => {
                            const { key, name } = link
                            return (
                                <button key={key} 
                                    className='header--menus--tab'
                                    onClick={() => { onClickLink(key) }}>
                                    {name}
                                </button>
                            )
                        })}
                    </div>
                </div>
                {this.MobileMenu()}
            </Fragment>

        )
    }
}

Header.propTypes = {
    onClickLink: PropTypes.func.isRequired
}

