import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Modal from 'react-modal'

import { RxCross1 } from 'react-icons/rx'

Modal.setAppElement('#root')

export default class DisclaimerModal extends Component {
    constructor (props) {
        super(props)
        this.handleCloseModal = this.handleCloseModal.bind(this)
    }

    handleCloseModal () {
        const { onClickClose } = this.props
        onClickClose()
    }

    render () {
        return (
            <Modal overlayClassName='disclaimer-modal--overlay'
                className='disclaimer-modal'
                isOpen
                shouldCloseOnOverlayClick
                onRequestClose={this.handleCloseModal}>
                <div className='disclaimer-modal--main horizontal-centered'>
                    <div className='disclaimer-modal--header'>
                        <div className='disclaimer-modal--title'>{`Website Disclaimer`}</div>
                        <button className='disclaimer-modal--close-button' 
                            onClick={this.handleCloseModal}><RxCross1 /></button>
                    </div>
                    <div className='disclaimer-modal--body'>
                        <p>
                            {`All visitors to this website ("`}
                            <strong>{'users'}</strong>
                            {`" or "`}<strong>{'you'}</strong>
                            {`" as the case may be) should take note of this disclaimer.`}</p>
                        <p>
                            {`Antelope Technology, including other companies operating under the "Antelope Technology" brand ("`}
                            <strong>{'we'}</strong>
                            {`", "`}
                            <strong>{'our'}</strong>
                            {`" or "`}
                            <strong>{'us'}</strong>
                            {`" as the case may be) engages solely in proprietary trading of cryptocurrencies and crypto derivatives. 
                            We are not licensed or regulated in any jurisdiction and we neither provide nor hold ourselves out as providing any services, including services regulated under the Singapore Payment Services Act 2019 as "payment services". 
                            You should take your own financial and legal advice prior to using our website and/or trading with us. `}</p>
                        <p>{`We disclaim all liability for the financial outcome of any trades entered into with us.`}</p>
                    </div>
                </div>
            </Modal>
        )
    }
}

DisclaimerModal.propTypes = {
    onClickClose: PropTypes.func.isRequired
}

DisclaimerModal.defaultProps = {
    onClickClose: () => {}
}